import React from "react";
import { useInView } from "react-intersection-observer";
import { GoPrimitiveDot } from "@react-icons/all-files/go/GoPrimitiveDot";

const StoryRoastery = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      className={`z-10 bg-white shadow-xl shadow-stone-900/10 w-full h-full flex flex-col justify-start items-center pt-16 md:pt-28`}
    >
      {/* Header */}
      <div className="w-full flex flex-col md:flex-row justify-end items-center px-3 md:px-20">
        <div
          ref={ref}
          className={`transition-all ease-in-out duration-[1200ms] ${
            inView
              ? "opacity-100 translate-x-0"
              : "opacity-0 translate-x-[100px]"
          } cursor-default w-full md:w-[70%] text-yellow-710 brightness-110 flex flex-col justify-center items-start font-runegifter text-4xl md:text-[2.6rem]`}
        >
          <div className="text-left w-full flex justify-start md:justify-end items-center">
            <GoPrimitiveDot className="text-2xl mr-2 flex md:hidden" />
            Roastery
            <GoPrimitiveDot className="text-2xl ml-2 flex md:hidden" />
          </div>
          <div className="text-justify  font-lufga300 text-base text-stone-900 mt-4">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </div>
        </div>
      </div>

      {/* Content */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } w-full flex flex-col md:flex-row justify-start items-start md:pr-20 mt-8 md:mt-16 bg-yellow-510`}
      >
        <div className="md:hidden flex flex-col w-full md:w-[35%] pt-5 md:pt-20 mr-20 px-3 md:px-0">
          <div className="cursor-default text-yellow-710 brightness-110 font-runegifter text-4xl md:text-[2.6rem] md:text-right">
            Serve with Love
          </div>
          <div className="text-justify font-lufga300 text-base text-stone-900 mt-2 mb-6 md:mb-0">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </div>
        </div>

        <div className="w-full md:w-[65%] flex flex-col md:flex-row justify-center items-center">
          <div className="bg-stone-100 h-[420px] w-full md:w-1/2"></div>
          <div className="bg-stone-200 h-[420px] w-full md:w-1/2"></div>
        </div>

        <div className="hidden md:flex flex-col w-[35%] pt-5 md:pt-10 lg:pt-20 ml-20">
          <div className="cursor-default text-yellow-710 brightness-110 font-runegifter text-4xl md:text-[2.6rem] text-right">
            Serve with Love
          </div>
          <div className="text-justify font-lufga300 text-base text-stone-900 mt-2">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryRoastery;
