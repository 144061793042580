import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import ButtonUp from "../utilities/ButtomUp";
import StoryBannerTop from "../components/StoryBannerTop";
import StoryTextTop from "../components/StoryTextTop";
import StoryBakery from "../components/StoryBakery";
import StoryStudio from "../components/StoryStudio";
import StoryRoastery from "../components/StoryRoastery";
import Quote from "../components/Quote";
import StoryInfo from "../components/StoryInfo";
import GoodVibeCorner from "../components/GoodVibeCorner";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { HiArrowCircleUp } from "@react-icons/all-files/hi/HiArrowCircleUp";
import { IoCall } from "@react-icons/all-files/io5/IoCall";

const hover = "transition-all ease-in-out duration-300 hover:scale-[105%]";

const smooth = "transition-all ease-in-out duration-[500ms] ";

const Story = () => {
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });
    return () => scroll.destroy();
  });

  return (
    <div
      data-scroll-container
      className="scroll-smooth no-scrollbar relative overflow-hidden no-scrollbar flex flex-col justify-start items-center bg-gradient-to-b from-white to-stone-100 "
    >
      <div id="top2" />

      <div
        data-scroll-section
        className="relative h-full w-screen 2xl:w-new flex flex-col justify-center items-center"
      >
        <div
          id="top"
          data-scroll
          data-scroll-speed="-1"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="lg:w-full lg:h-full hidden lg:flex justify-center items-center "
        >
          <Navbar link={"story#contact"} />
          <a
            href={`#contact`}
            data-scroll-to
            className={`${hover} z-50 absolute top-12 right-[7.2rem] bg-stone-800 hover:bg-blue-710 hover:shadow-lg rounded-full h-16 w-16 mx-5 drop-shadow-md shadow-md hidden lg:grid place-content-center`}
          >
            <IoCall className="text-white text-[1.8rem]" />
          </a>
        </div>
      </div>

      <div
        id="container"
        data-scroll-section
        className="relative w-screen 2xl:w-new h-full flex flex-col justify-center items-center "
      >
        <div className="w-full h-full lg:hidden">
          <Navbar link={"story#contact"} />
        </div>

        {/* anchor link */}
        <div
          data-scroll
          data-scroll-sticky
          data-scroll-target="#container"
          className={`relative z-50 2xl:rounded-t-md 2xl:rounded-b-md hidden lg:grid place-content-center brightness-[95%] h-12 w-screen 2xl:w-new bg-stone-800 drop-shadow-md shadow-lg shadow-stone-500/10`}
        >
          <div className="flex justify-center items-center font-lufga300 text-white ">
            <a
              href="#bakery"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-r-2 border-stone-100`}
            >
              Bakery
            </a>
            <a
              href="#roastery"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-r-2 border-stone-100`}
            >
              Roastery
            </a>
            <a
              href="#studio"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-stone-100`}
            >
              Studio
            </a>
          </div>
        </div>

        {/* anchor link mobile*/}
        <div
          className={`grid lg:hidden place-content-center 2xl:rounded-t-md 2xl:rounded-b-md  brightness-[95%] h-12 w-screen 2xl:w-new bg-stone-800 drop-shadow-md shadow-lg shadow-stone-500/10`}
        >
          <div className="flex justify-center items-center font-lufga300 text-white ">
            <a
              href="#bakery"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-r-2 border-stone-100`}
            >
              Bakery
            </a>
            <a
              href="#roastery"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-r-2 border-stone-100`}
            >
              Roastery
            </a>
            <a
              href="#studio"
              data-scroll-to
              className={`${smooth} hover:text-yellow-710 hover:brightness-150 px-10 border-stone-100`}
            >
              Studio
            </a>
          </div>
        </div>

        <div className="mt-8 md:mt-12" />

        <StoryTextTop />

        <div
          data-scroll
          data-scroll-speed="1"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <StoryBannerTop />
        </div>

        <div
          id="bakery"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <StoryBakery />
        </div>

        <div
          id="roastery"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <StoryRoastery />
        </div>

        <div
          id="studio"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="top"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <StoryStudio />
        </div>

        <StoryInfo />
        <Quote />
        <GoodVibeCorner />

        <div
          id="contact"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="bottom"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <Contact />
        </div>

        <div
          id="top"
          data-scroll
          data-scroll-speed="0"
          data-scroll-position="bottom"
          data-scroll-direction="veritcal"
          className="relative w-full h-full flex justify-center items-center"
        >
          <Footer />
          <a
            href={`#top`}
            data-scroll-to
            className="absolute z-40 bottom-8 right-6 lg:right-16"
          >
            <button
              aria-label="up"
              className={`focus:outline-none z-50 bg-white h-12 w-12 md:h-14 md:w-14 rounded-full shadow-lg text-xl md:text-2xl text-green-110 hover:text-blue-710 hidden lg:flex justify-center items-center`}
            >
              <HiArrowCircleUp />
            </button>
          </a>
        </div>

        <div className="flex lg:hidden">
          <ButtonUp link={"story#top2"} />
        </div>
      </div>
    </div>
  );
};

export default Story;
