import React from "react";
import { useInView } from "react-intersection-observer";
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft";
import { ImQuotesRight } from "@react-icons/all-files/im/ImQuotesRight";

const Quote = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`z-20 bg-white shadow-xl shadow-stone-900/10 relative h-full w-full px-3 md:px-20 pt-16 md:pt-28 pb-16 md:pb-32 font-goldenHopes`}
    >
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        }  leading-tight flex justify-start items-start text-center`}
      >
        <div className="inline-block text-2xl md:text-3xl h-auto mx-4 text-yellow-710 brightness-110">
          <ImQuotesLeft />
        </div>
        <div className="inline-block text-5xl md:text-6xl text-yellow-710 brightness-110">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
          lobortis, ex vitae elementum malesuada, risus purus.
        </div>

        <div className="inline-block text-2xl md:text-3xl h-auto mx-4 text-yellow-710 brightness-110">
          <ImQuotesRight />
        </div>
      </div>
    </div>
  );
};

export default Quote;
