import React from "react";
import { useInView } from "react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CgArrowLongRight } from "@react-icons/all-files/cg/CgArrowLongRight";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper";

const smooth = "transition-all ease-in-out duration-300";

const query = graphql`
  {
    allContentfulImageCorner(sort: { order: ASC, fields: image___createdAt }) {
      nodes {
        id
        title
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const GoodVibeCorner = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const {
    allContentfulImageCorner: { nodes: items },
  } = useStaticQuery(query);

  return (
    <div
      ref={ref}
      className="2xl:rounded-b-md z-20 flex flex-col justify-start items-center w-full px-3 md:px-20 bg-white shadow-xl shadow-stone-900/10"
    >
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 -translate-x-0"
            : "opacity-0 -translate-x-[100px]"
        } font-lufga400 italic text-3xl mb-6 md:mb-8 w-full`}
      >
        #The Good Vibe Corner
      </div>

      {/* desktop */}
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } hidden md:flex flex-wrap justify-center items-center w-full`}
      >
        {items.map((post) => {
          const { id, title, image } = post;
          const pathToImage = getImage(image);

          return (
            <div
              key={id}
              className={`${smooth} group relative aspect-square mx-3 mb-6 rounded-lg shadow-md hover:shadow-xl shadow-stone-800/10 drop-shadow-md flex flex-col justify-center items-center`}
            >
              <GatsbyImage
                image={pathToImage}
                alt={title}
                className={`${smooth} duration-[300ms] z-20 w-[16rem] h-[16rem] 2xl:w-[18rem] 2xl:h-[18rem] rounded-lg`}
              />
            </div>
          );
        })}
      </div>

      {/* mobile */}
      <div
        ref={ref}
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } md:hidden w-full h-full px-4`}
      >
        <Swiper
          autoplay={{
            delay: 10000,
          }}
          navigation={true}
          pagination={true}
          modules={[Autoplay, Navigation, Pagination]}
          spaceBetween={30}
          className={`customized-swiper xl:rounded-t-md aspect-square rounded-lg shadow-md shadow-stone-800/10 drop-shadow-md`}
        >
          {items.map((post) => {
            const { id, title, image } = post;
            const pathToImage = getImage(image);

            return (
              <SwiperSlide key={id}>
                <GatsbyImage
                  image={pathToImage}
                  alt={title}
                  className={`image-swiper aspect-square rounded-lg`}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView
            ? "opacity-100 -translate-x-0"
            : "opacity-0 -translate-x-[100px]"
        } md:hidden font-lufga200 italic text-base mt-4 w-full pr-5 flex justify-end items-center`}
      >
        swipe to see more <CgArrowLongRight className="ml-3" />
      </div>

      <div className="w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl mt-16 mb-12" />
    </div>
  );
};

export default GoodVibeCorner;
