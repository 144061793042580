import React from "react";
import { useInView } from "react-intersection-observer";

const StoryInfo = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`z-20 bg-white shadow-xl shadow-stone-900/10 pt-10 pb-8 w-full flex flex-col md:flex-row justify-center items-center md:items-start`}
    >
      <div className="flex md:hidden w-[40%] md:w-[20%] h-[3px] bg-stone-300 rounded-xl mb-12" />
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
        } hidden md:flex w-full md:w-[45%] h-[450px] bg-stone-200 md:rounded-lg mr-20 drop-shadow-md shadow-lg shadow-stone-500/20`}
      ></div>
      <div
        className={`transition-all ease-in-out duration-[1200ms] ${
          inView ? "opacity-100 translate-x-0" : "opacity-0 translate-x-[100px]"
        } w-full md:w-[30%] flex flex-col justify-center items-start font-lufga300 px-3`}
      >
        <div className="cursor-default w-full text-yellow-710 brightness-110 font-runegifter text-[2.6rem] ">
          <div className="w-full ">Passion</div>
          <div className="w-full">From Day 1</div>
        </div>
        <div className="w-[200px] h-[3px] bg-stone-300 my-4 md:my-10"></div>
        <div className="w-full text-justify">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. When an unknown printer took a galley of type and scrambled
          it to make a type specimen book.
        </div>
      </div>
      <div className="flex md:hidden w-full h-[450px] bg-stone-200 drop-shadow-md shadow-lg shadow-stone-500/20 my-8"></div>
    </div>
  );
};

export default StoryInfo;
