import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { useInView } from "react-intersection-observer";

const LoadTopBanner = graphql`
  {
    allFile(
      filter: { name: { eq: "story_banner" }, extension: { eq: "jpg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;

const StoryBannerTop = () => {
  const data = useStaticQuery(LoadTopBanner);
  const image = getImage(data.allFile.nodes[0]);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={`transition-all ease-in-out duration-[1200ms] ${
        inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100px]"
      } drop-shadow-md shadow-md shadow-stone-500/20 2xl:rounded-t-md`}
    >
      <GatsbyImage
        image={image}
        alt="story_banner"
        className={`h-[300px] md:h-[600px] 2xl:rounded-t-md`}
      />
    </div>
  );
};

export default StoryBannerTop;
